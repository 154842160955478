<template>
    <div>
        <b-card>
            <b-row v-if="roleData !== null">
                <b-col>
                    <b-form-group label="Employee Role Name">
                        {{roleData.userRoleName}}
                    </b-form-group>
                </b-col>
                 <b-col>
                     <b-form-group label="No. of Employee">
                        {{roleData.assignUser.length}}
                    </b-form-group>
                </b-col>
                 <b-col>
                    <b-form-group label="Assigned Modules">
                        <span v-for="data in roleData.assignModules" :key="data.id">
                            {{data.name}},
                        </span>                        
                    </b-form-group>
                </b-col>
            </b-row>
        </b-card>
        <b-card>
            <b-row class="mb-2">
                <b-col md="6" class="d-flex align-items-center">
                    <h4 class="mb-0 table_title">Assigned Employee</h4>
                </b-col>
                <b-col md="6">
                    <div class="content-header-right text-md-right top_btn_right">
                    <button @click="getData(), openRoleModel=true" class="btn btn-icon btn-primary">Add New Employee</button>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-table
                    responsive
                    :items="assigneUsers"
                    :fields="assigneUserColumn"
                    show-empty
                    empty-text="No matching records found"
                    class="position-relative employee_add_table"
                    >
                        <template #cell(dateOfJoining)="data">
                            {{data.item.dateOfJoining.split("-")[2]}} {{months[data.item.dateOfJoining.split("-")[1]-1]}}, {{data.item.dateOfJoining.split("-")[0]}}
                        </template>
                        <template #cell(personalPhoneNo)="data">
                            {{(`${data.item.countryPhoneCode ? data.item.countryPhoneCode.dialCode : '+91'} ` + ((data.item.countryPhoneCode ? formatPhoneNumber(data.item.personalPhoneNo,data.item.countryPhoneCode.mask) : formatPhoneNumber(data.item.personalPhoneNo,'99999 99999'))))}}
                        </template>
                        <template #cell(emergencyPhoneNo)="data">
                            {{(`${data.item.countryPhoneCode ? data.item.countryPhoneCode.dialCode : '+91'} ` + ((data.item.countryPhoneCode ? formatPhoneNumber(data.item.emergencyPhoneNo,data.item.countryPhoneCode.mask) : formatPhoneNumber(data.item.emergencyPhoneNo,'99999 99999'))))}}
                        </template>
                    </b-table>
                </b-col>               
            </b-row>
        </b-card>
        <!-- MODEL OF ADD MORE USERS -->
        <b-modal
        id="modal-create-role"
        cancel-variant="outline-secondary"
        ok-title="Save"
        cancel-title="Cancel"
        centered
        v-model="openRoleModel"
        title="Add Employee"
        @ok='updateAssigneUser($event)'
        :ok-disabled='modalSpinner'
        :cancel-disabled='modalSpinner'
        >    
        <div>            
            <validation-observer ref="createuserRoleValid">  
            <form>             
             <b-form-group      
                    class="mb-2"
                    label-align-sm="left"
                    label="Select Employee"        
                >
                <!-- <b-form-select
                    style="{width: 490px;}"
                    id="perPageSelect"
                    label-field="name"
                    value-field="id"
                    v-model="selectedUsers"
                    :options="AllUsers"         
                />     -->               
                <div class="course_wrapper">
                    <div class="dropdown" @click="showDropdown">
                    <div class="overselect"></div>
                    <validation-provider
                            #default="{ errors }"
                            name="user"  
                            rules="required"                                         
                    >
                    <select class="c-form-input form-control" v-model="selectedUsers" :disabled='modalSpinner' :state="errors.length > 0 ? false:null">
                        <!-- <option value="" selected disabled>Please Select One</option>                                  -->
                    </select>
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </div>
                    <div class="multiselect multiselect_employee" v-if="show">
                    <ul>
                        <li class="mb-1">
                            <input type="text" class="form-control" placeholder="Search..." v-model="searchValue"/>
                        </li>
                    <!-- </ul>    
                    <ul> -->                                     
                        <li v-for="(option, index) in filteredUsers" :key="index" class="">
                        <input type="checkbox" :id="index" :hidden='option.currnetEmp==option.id' :disabled='modalSpinner' :value="option.id" v-model="selectedUsers">
                        <label :hidden='option.currnetEmp==option.id' :for="index">{{ option.name }}</label>
                        </li>       
                        <li v-show="filteredUsers.length <= 0">
                            <small class="text-danger">employee data not exist</small>
                        </li>
                    </ul>
                    </div>
                </div>    
                </b-form-group>
            </form>
            </validation-observer>
        </div>
        </b-modal>

       <!-- UPDATE ROLE -->
       <b-modal
        id="modal-create-role"
        cancel-variant="outline-secondary"
        ok-title="Save"
        cancel-title="Cancel"
        centered
        v-model="editable"
        title="Edit Employee Role"
        @ok="updateUserRole($event)"
        :ok-disabled='modalSpinner'
        :cancel-disabled='modalSpinner'
        >
         <validation-observer ref="updateuserRoleValid">    
            <form ref="form"  @submit.stop.prevent="handleSubmitedit">  
                <b-spinner variant="primary" v-if="modalSpinner" large class="pageLoadSpinners"/>                         
                <b-row>
                    <b-col @mouseup="eventfordropdown">
                        <b-form-group label="Employee Role Name" label-for="roleName" >
                            <validation-provider
                                #default="{ errors }"
                                name="role name"                                
                                rules="required"
                            >
                                <b-form-input
                                id="userRole"
                                name='role'
                                :state="errors.length > 0 ? false:null"
                                placeholder="Enter Text"   
                                v-model="userRole.name"  
                                :disabled='modalSpinner'           
                                >               
                                </b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="Select Employee" label-for="empName">
                            <!-- <v-select
                            v-model="selectedEmp"
                            :options='empArray'
                            id="empName"
                            :reduce="name => name.id" label="name" 
                            placeholder="select"
                            />                -->
                            <div class="course_wrapper">
                            <div class="dropdown" @click="showDropdown">
                            <div class="overselect"></div>
                            <validation-provider
                                    #default="{ errors }"
                                    name="user"                                           
                            >
                            <select class="c-form-input form-control" :disabled='modalSpinner' v-model="selectedUsers" :state="errors.length > 0 ? false:null">
                            </select>
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            </div>
                            <div class="multiselect multiselect_employee" v-if="show">
                            <ul>
                                <li class="mb-1">
                                    <input type="text" class="form-control" placeholder="Search..." v-model="searchValue"/>
                                </li>                                                  
                                <li v-for="(option, index) in filteredUsers" :key="index" class="">
                                    <input type="checkbox" :id="index" :value="option.id" :hidden='option.currnetEmp==option.id' v-model="selectedUsers">
                                    <label :hidden='option.currnetEmp==option.id' :for="index">{{ option.name }}</label>
                                </li>  
                                <li v-show="filteredUsers.length <= 0">
                                    <small class="text-danger"> employee data not exist</small>
                                </li>                                  
                            </ul>
                            </div>
                        </div> 
                        </b-form-group>
                    </b-col>
                </b-row> 
                <div @mouseup="eventfordropdown">
                <b-form-group>
                    <label @mouseup="eventfordropdown">Assigned Modules</label>
                    <b-row>
                        <b-col md="6" sm="2" class="pb-1 pt-1" @mouseup="eventfordropdown">
                            <b-form-checkbox
                                id="checkbox-1"
                                v-model="userRole.StudentInquiry.isSelected"
                                name="checkbox-1"
                                value="Student Inquiry"                               
                                @change="StudentInquiryChange"                               
                                :disabled='modalSpinner'
                            >
                            Student Inquiry
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="6" sm="2" class="pb-1 pt-1" @mouseup="eventfordropdown">
                            <validation-provider
                                #default="{ errors }"
                                name="Student Inquiry"                                
                                rules="required"                                
                             >
                                <b-form-radio-group
                                class=""
                                name='studentInq'
                                :options="['Read', 'Write']"   
                                v-model="userRole.StudentInquiry.allow" 
                                :state="errors.length > 0 ? false:null"
                                :disabled='userRole.StudentInquiry.isSelected.length == 0'                           
                                >
                                </b-form-radio-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row >
                        <b-col md="6" sm="2" class="pb-1 pt-1" @mouseup="eventfordropdown">
                            <b-form-checkbox
                                id="checkbox-2"
                                v-model="userRole.StudentMgmt.isSelected"
                                name="checkbox-name-2"
                                value="Student List"
                                @change="StudentMgmtChange()"
                                :disabled='modalSpinner'
                            >
                            Student List
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="6" sm="2" class="pb-1 pt-1" @mouseup="eventfordropdown">
                            <validation-provider
                                #default="{ errors }"
                                name="Student Managment"                                
                                rules="required"                                
                             >
                            <b-form-radio-group
                            class=""
                            name='studentMgmt'
                            :options="['Read', 'Write']"  
                            v-model="userRole.StudentMgmt.allow" 
                            :state="errors.length > 0 ? false:null"
                            :disabled='userRole.StudentMgmt.isSelected.length == 0'                                 
                            >
                            </b-form-radio-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="6" sm="2" class="pb-1 pt-1" @mouseup="eventfordropdown">
                            <b-form-checkbox
                                id="checkbox-3"
                                v-model="userRole.AdminUserMgmt.isSelected"
                                name="checkbox-name-3"
                                value="Admin User"
                                @change="AdminUserMgmtChange()"
                                :disabled='modalSpinner'
                            >
                            Admin User
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="6" sm="2" class="pb-1 pt-1" @mouseup="eventfordropdown">
                            <validation-provider
                                #default="{ errors }"
                                name="Admin User Managment"                                
                                rules="required"                                
                             >
                            <b-form-radio-group
                            class=""
                            name='adminUserMgmt'
                            :options="['Read', 'Write']"    
                            v-model="userRole.AdminUserMgmt.allow"
                            :state="errors.length > 0 ? false:null" 
                            :disabled='userRole.AdminUserMgmt.isSelected.length == 0'                              
                            >
                            </b-form-radio-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row >
                        <b-col md="6" sm="2" class="pb-1 pt-1" @mouseup="eventfordropdown">
                            <b-form-checkbox
                                id="checkbox-4"
                                v-model="userRole.UniversityCourseMgmt.isSelected"
                                name="checkbox-name-4"
                                value="University/Courses"
                                @change="UniversityCourseMgmtChange()"
                                :disabled='modalSpinner'
                            >
                            University/Courses
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="6" sm="2" class="pb-1 pt-1" @mouseup="eventfordropdown">
                            <validation-provider
                                #default="{ errors }"
                                name="Course Managment"                                
                                rules="required"                                
                             >
                            <b-form-radio-group
                            class=""
                            name='courceMgmt'
                            :options="['Read', 'Write']"   
                            v-model="userRole.UniversityCourseMgmt.allow" 
                            :state="errors.length > 0 ? false:null"
                            :disabled='userRole.UniversityCourseMgmt.isSelected.length == 0'                           
                            >
                            </b-form-radio-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="6" sm="2" class="pb-1 pt-1" @mouseup="eventfordropdown">
                            <b-form-checkbox
                                id="checkbox-5"
                                v-model="userRole.docModuleMgmt.isSelected"
                                name="checkbox-name-5"
                                value="Documents"
                                @change="docModuleMgmtChange()"
                                :disabled='modalSpinner'
                            >
                            Documents
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="6" sm="2" class="pb-1 pt-1" @mouseup="eventfordropdown">
                            <validation-provider
                                #default="{ errors }"
                                name="Doc. Category Managment"                                
                                rules="required"                                
                             >
                            <b-form-radio-group
                            class=""
                            :options="['Read', 'Write']" 
                            name='docModule'    
                            v-model="userRole.docModuleMgmt.allow"  
                            :state="errors.length > 0 ? false:null"
                            :disabled='userRole.docModuleMgmt.isSelected.length == 0'                         
                            >
                            </b-form-radio-group>
                             <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="6" sm="2" class="pb-1 pt-1" @mouseup="eventfordropdown">
                            <b-form-checkbox
                                id="checkbox-6"
                                v-model="userRole.companyDoc.isSelected"
                                name="checkbox-name-6"
                                value="Company Document" 
                                @change="companyDocChange()"   
                                :disabled='modalSpinner'                            
                            >
                            Company Document
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="6" sm="2" class="pb-1 pt-1" @mouseup="eventfordropdown">
                            <validation-provider
                                #default="{ errors }"
                                name="company document"                                
                                rules="required"                                
                             >
                            <b-form-radio-group
                            class=""
                            :options="['Read', 'Write']"  
                            v-model="userRole.companyDoc.allow"
                            name='companydoc'
                            :state="errors.length > 0 ? false:null"   
                            :disabled='userRole.companyDoc.isSelected.length == 0'                                                                   
                            >
                            </b-form-radio-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <b-form-checkbox
                                id="checkbox-7"
                                v-model="userRole.promotional.isSelected"
                                name="checkbox-1"
                                value="Promotional Notification" 
                                @change="promotionalChange()" 
                                :disabled='isSpinner'                              
                            >
                            Promotional notification
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <validation-provider
                                #default="{ errors }"
                                name="promotional notification"                                
                                rules="required"                                
                             >
                            <b-form-radio-group
                            class=""
                            :options="['Read', 'Write']"  
                            v-model="userRole.promotional.allow"
                            name='promotional'
                            :state="errors.length > 0 ? false:null"   
                            :disabled='userRole.promotional.isSelected.length == 0'                                                                   
                            >
                            </b-form-radio-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <b-form-checkbox
                                id="checkbox-8"
                                v-model="userRole.announcement.isSelected"
                                name="checkbox-1"
                                value="Announcement" 
                                @change="announcementChange()" 
                                :disabled='isSpinner'                              
                            >
                            Announcement
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <validation-provider
                                #default="{ errors }"
                                name="announcement"                                
                                rules="required"                                
                             >
                            <b-form-radio-group
                            class=""
                            :options="['Read', 'Write']"  
                            v-model="userRole.announcement.allow"
                            name='announcement'
                            :state="errors.length > 0 ? false:null"   
                            :disabled='userRole.announcement.isSelected.length == 0'                                                                   
                            >
                            </b-form-radio-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <b-form-checkbox
                                id="checkbox-9"
                                v-model="userRole.community.isSelected"
                                name="checkbox-1"
                                value="Community" 
                                @change="communityChange()" 
                                :disabled='isSpinner'                              
                            >
                            Community
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <validation-provider
                                #default="{ errors }"
                                name="community"                                
                                rules="required"                                
                             >
                            <b-form-radio-group
                            class=""
                            :options="['Read', 'Write']"  
                            v-model="userRole.community.allow"
                            name='community'
                            :state="errors.length > 0 ? false:null"   
                            :disabled='userRole.community.isSelected.length == 0'                                                                   
                            >
                            </b-form-radio-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <b-form-checkbox
                                id="checkbox-10"
                                v-model="userRole.infoDoc.isSelected"
                                name="checkbox-1"
                                value="Information Document" 
                                @change="infoDocChange()" 
                                :disabled='isSpinner'                              
                            >
                            Information Document
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <validation-provider
                                #default="{ errors }"
                                name="infoDoc"                                
                                rules="required"                                
                             >
                            <b-form-radio-group
                            class=""
                            :options="['Read', 'Write']"  
                            v-model="userRole.infoDoc.allow"
                            name='infoDoc'
                            :state="errors.length > 0 ? false:null"   
                            :disabled='userRole.infoDoc.isSelected.length == 0'                                                                   
                            >
                            </b-form-radio-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <b-form-checkbox
                                id="checkbox-12"
                                v-model="userRole.eduMent.isSelected"
                                name="checkbox-1"
                                value="Education Fields Management" 
                                @change="fiedlMgntChange()" 
                                :disabled='isSpinner'                              
                            >
                            Education Fields Management
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <validation-provider
                                #default="{ errors }"
                                name="eduMent"                                
                                rules="required"                                
                             >
                            <b-form-radio-group
                            class=""
                            :options="['Read', 'Write']"  
                            v-model="userRole.eduMent.allow"
                            name='eduMent'
                            :state="errors.length > 0 ? false:null"   
                            :disabled='userRole.eduMent.isSelected.length == 0'                                                                   
                            >
                            </b-form-radio-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <b-form-checkbox
                                id="checkbox-11"
                                v-model="userRole.noticeDoc.isSelected"
                                name="checkbox-1"
                                value="Notice Management" 
                                @change="noticeMangChange()" 
                                :disabled='isSpinner'                              
                            >
                            Notice Management
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <validation-provider
                                #default="{ errors }"
                                name="noticeDoc"                                
                                rules="required"                                
                             >
                            <b-form-radio-group
                            class=""
                            :options="['Read', 'Write']"  
                            v-model="userRole.noticeDoc.allow"
                            name='noticeDoc'
                            :state="errors.length > 0 ? false:null"   
                            :disabled='userRole.noticeDoc.isSelected.length == 0'                                                                   
                            >
                            </b-form-radio-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <b-form-checkbox
                                id="checkbox-13"
                                v-model="userRole.noteDoc.isSelected"
                                name="checkbox-1"
                                value="Application Note Management" 
                                @change="noteMangChange()" 
                                :disabled='isSpinner'                              
                            >
                            Application Note Management
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <validation-provider
                                #default="{ errors }"
                                name="noteDoc"                                
                                rules="required"                                
                             >
                            <b-form-radio-group
                            class=""
                            :options="['Read', 'Write']"  
                            v-model="userRole.noteDoc.allow"
                            name='noteDoc'
                            :state="errors.length > 0 ? false:null"   
                            :disabled='userRole.noteDoc.isSelected.length == 0'                                                                   
                            >
                            </b-form-radio-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                </b-form-group> 
                </div>
            </form>
       </validation-observer>
       </b-modal> 
    </div>
</template>
<script>
import {
 BCard,
 BFormGroup,
 BRow,
 BCol,
 BTable,
 BModal,
 BFormSelect,
 BFormRadioGroup,
 BFormCheckbox,
 BFormInput,
 BSpinner
} from 'bootstrap-vue'
import { dbCollections } from '@/utils/firebaseCollection.js';
import * as getQuery from '@/utils/FirebaseQueries/getQueries/getQueries.js'
import {  extend ,ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules'
import * as updateQuery from '@/utils/FirebaseQueries/updateQueries/updateQueries.js';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import firebaseTimeStamp from 'firebase'
import formatPhoneNumber from '@/utils/phoneNumberFormat.js';
extend('required',required)
export default {
    components: {
        BSpinner,
        BCard,
        BFormGroup,
        BRow,
        BCol,
        BTable,
        BModal,
        BFormSelect,
        ValidationProvider,
        ValidationObserver,
        BFormRadioGroup,
        BFormCheckbox,
        BFormInput
    },
    data(){
        return{
            months:["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
            roleData:null,
            assigneUsers:[],
            assigneUserColumn:[
                {key:'employeeId', label:'Employee Id',sortable: true},
                {key:'name', label:'Employee Name',sortable: true},
                {key:'personalPhoneNo', label:'Phone No.',sortable: true},
                {key:'companyEmail', label:'Company Email',sortable: true},
                {key:'dateOfJoining', label:'Joining Date',sortable: true},
                {key:'emergencyPhoneNo', label:'Emergency Phone No.',sortable: true},
                {key:'aadharCardNo', label:'Adharcard No.',sortable: true}
            ],
            openRoleModel:false,
            selectedUsers:[],
            AllUsers:[],
            show:false,
            searchValue:'',
            editable:false,
            userRole:{
               name:'',
               selectedUser:[],
               assignModules:[],
               StudentInquiry:{
                   isSelected:[],
                   allow:false
               },
               StudentMgmt:{
                   isSelected:[],
                   allow:false
               },
               AdminUserMgmt:{
                   isSelected:[],
                   allow:false
               },
               UniversityCourseMgmt:{
                   isSelected:[],
                   allow:false
               },
               docModuleMgmt:{
                   isSelected:[],
                   allow:false
               },
               companyDoc:{
                   isSelected:[],
                   allow:false
               },
               promotional:{
                   isSelected:[],
                   allow:false
               },
                announcement:{
                   isSelected:[],
                   allow:false
               },
                community:{
                   isSelected:[],
                   allow:false
               },
                infoDoc:{
                   isSelected:[],
                   allow:false
               },       
               eduMent:{
                    isSelected:[],
                   allow:false
               },      
               noticeDoc:{
                   isSelected:[],
                   allow:false
               } ,      
               noteDoc:{
                   isSelected:[],
                   allow:false
               }       
           },
           assignModules:[],
           isSpinner:false,
           allRoleList:[],
           assignuserRoleId:[],
           previouseAssignUsers:[],
           modalSpinner:false
        }
    },
    created(){
        this.init()
        this.$root.$on('userRoleEditOpen',()=>{
            this.init(); 
            // setTimeout(()=>{
                this.editable = true  
            // },1000)     
        })
    },
    methods:{
        formatPhoneNumber,
        getAssignedUsers(data,cb){
            getQuery.getDataFromRootCollectionWithDocID(dbCollections.USERS,data,res=>{
                if(res){
                    if(res.isDeleted == false){
                    cb(res)
                    }else{
                        cb(false)
                    }
                }else{
                    cb(updateFinalUserRole)
                }
            })            
        },
        init(){
            getQuery.getDataFromRootCollectionWithDocID(dbCollections.USERROLES,this.$route.params.id,res=>{
                this.assigneUsers = []
                if(res.isDeleted == false){
                    this.roleData = res;
                    this.previouseAssignUsers = res.assignUser;
                    this.userRole.name = this.roleData.userRoleName;

                    if(this.roleData.assignModules.length > 0){
                        this.roleData.assignModules.forEach(element=>{
                            if(element.name == 'Student Inquiry'){
                                this.userRole.StudentInquiry.isSelected = [];
                                this.userRole.StudentInquiry.isSelected.push(element.name)
                                this.userRole.StudentInquiry.allow = element.allow
                            }
                            if(element.name == 'Student List'){
                                this.userRole.StudentMgmt.isSelected = [];
                                this.userRole.StudentMgmt.isSelected.push(element.name)
                                this.userRole.StudentMgmt.allow = element.allow
                            }
                            if(element.name == 'Admin User'){
                                this.userRole.AdminUserMgmt.isSelected = [];
                                this.userRole.AdminUserMgmt.isSelected.push(element.name)
                                this.userRole.AdminUserMgmt.allow = element.allow
                            }
                            if(element.name == 'University/Courses'){
                                this.userRole.UniversityCourseMgmt.isSelected = [];
                                this.userRole.UniversityCourseMgmt.isSelected.push(element.name)
                                this.userRole.UniversityCourseMgmt.allow = element.allow
                            }
                            if(element.name == 'Documents'){
                                this.userRole.docModuleMgmt.isSelected = []
                                this.userRole.docModuleMgmt.isSelected.push(element.name)
                                this.userRole.docModuleMgmt.allow = element.allow
                            }
                            if(element.name == 'Company Document'){
                                this.userRole.companyDoc.isSelected = [];
                                this.userRole.companyDoc.isSelected.push(element.name)
                                this.userRole.companyDoc.allow = element.allow
                            }
                            if(element.name == 'Promotional Notification'){
                                this.userRole.promotional.isSelected = [];
                                this.userRole.promotional.isSelected.push(element.name)
                                this.userRole.promotional.allow = element.allow
                            }
                            if(element.name == 'Announcement'){
                                this.userRole.announcement.isSelected = [];
                                this.userRole.announcement.isSelected.push(element.name)
                                this.userRole.announcement.allow = element.allow
                            }
                            if(element.name == 'Community'){
                                this.userRole.community.isSelected = [];
                                this.userRole.community.isSelected.push(element.name)
                                this.userRole.community.allow = element.allow
                            }
                            if(element.name == 'Information Document'){
                                this.userRole.infoDoc.isSelected = [];
                                this.userRole.infoDoc.isSelected.push(element.name)
                                this.userRole.infoDoc.allow = element.allow
                            }
                            if(element.name == 'Education Fields Management'){
                                this.userRole.eduMent.isSelected = [];
                                this.userRole.eduMent.isSelected.push(element.name)
                                this.userRole.eduMent.allow = element.allow
                            }
                            if(element.name == 'Notice Management'){
                                this.userRole.noticeDoc.isSelected = [];
                                this.userRole.noticeDoc.isSelected.push(element.name)
                                this.userRole.noticeDoc.allow = element.allow
                            }
                            if(element.name == 'Application Note Management'){
                                this.userRole.noteDoc.isSelected = [];
                                this.userRole.noteDoc.isSelected.push(element.name)
                                this.userRole.noteDoc.allow = element.allow
                            }
                        })
                    }

                    if(Object.keys(this.roleData).length > 0){
                        this.selectedUsers = this.roleData.assignUser;                        

                        if(this.roleData.assignUser.length > 0){
                            let count = 0;
                            let fileArry = this.roleData.assignUser;                          

                            if(fileArry.length > 0){

                                const uploadFileFunction = (row) => {

                                if(count >= fileArry.length){                        
                                    return  
                                }
                                else{
                                    this.getAssignedUsers(row,(saveRes)=>{
                                        if(saveRes !== false){

                                            if(this.assigneUsers.length > 0){

                                                let findInd = this.assigneUsers.findIndex(vals=>{
                                                    return vals.id == saveRes.id
                                                })
                                                if(findInd <= -1){
                                                    this.assigneUsers.push(saveRes)
                                                }
                                            }
                                            else{ //first time push..
                                            this.assigneUsers.push(saveRes)
                                            }
                                        }
                                        count += 1;
                                        uploadFileFunction(fileArry[count]);

                                    }); 

                                }             
                                }
                                this.assigneUsers = []
                                uploadFileFunction(fileArry[count]);
                            }
                        }
                    }
                }
            })
            setTimeout(()=>{
                getQuery.getDataWithSpecificWhereCondition(dbCollections.USERROLES,'isDeleted','==',false,res=>{ 
                    this.allRoleList = res;
                    if(res.length){
                        res.forEach(element=>{                        
                            if(element.assignUser.length > 0){                   
                                let tempArray = element.assignUser;
                                if(this.roleData.assignUser.length > 0){
                                    this.roleData.assignUser.forEach(eachId=>{
                                        let serchIndex = tempArray.findIndex(vaal=>{
                                            return vaal == eachId
                                        })
                                        if(serchIndex > -1){
                                            tempArray.splice(serchIndex,1)
                                        }
                                    })                                    
                                }                                
                                Array.prototype.push.apply(this.assignuserRoleId,tempArray);
                            }                        
                        })
                    }                    
                }) 
                this.getData()
            },1000)            
        },
        getData(){
            var self = this;
            getQuery.getDataWithSpecificWhereCondition(dbCollections.USERS,'isDeleted','==',false,res=>{
                this.AllUsers = [];
                if(res.length > 0){
                    this.selectedUsers = this.roleData.assignUser;
                    res.forEach(element => {                        
                        if(element.role.toLowerCase() == "employee"){
                            if(self.assignuserRoleId.length > 0){
                               let searchIndex = this.assignuserRoleId.findIndex(selectedVal=>{
                                    return selectedVal == element.id
                               })
                               if(searchIndex <= -1){
                                     if(self.AllUsers.length > 0){
                                    let removeDup = this.AllUsers.findIndex(selectedVal=>{
                                        return selectedVal.id == element.id
                                    })
                                    if(removeDup <= -1){
                                        self.AllUsers.push({'name':element.name,'id':element.id})
                                    }
                                    }else{
                                        self.AllUsers.push({'name':element.name,'id':element.id})
                                    }
                               }
                            }
                            else{    
                                if(self.AllUsers.length > 0){
                                    let removeDup = this.AllUsers.findIndex(selectedVal=>{
                                        return selectedVal.id == element.id
                                    })
                                    if(removeDup <= -1){
                                        self.AllUsers.push({'name':element.name,'id':element.id})
                                    }
                                }else{
                                    self.AllUsers.push({'name':element.name,'id':element.id})
                                }
                            }           
                        }
                    });                    
                    // this.openRoleModel=true;
                    this.show=false;
                    // this.openRoleModel = false;
                }
            })
        },
        showDropdown() {
        this.show = !this.show
       },
       eventfordropdown(){
           this.show = false      
       },
       updateAssigneUser(event){
           // Prevent modal from closing
        event.preventDefault()
        // Trigger submit handler
        this.handleSubmit()  
       },
       removeEmpRole(userId,cb){
           if(userId){
            let updateField = {
                'employeeRole':null,
                'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp()
            }
            updateQuery.updateRootCollectionDataByDocId(dbCollections.USERS,userId,updateField,updateRes=>{
                cb(true)
            })
          }else{
            cb(true)
          }
       },
       updateFinalAssignUser(storeAssignUser){
           var self = this;
            let updateField = {
                'assignUser': storeAssignUser
            } 
            
            updateQuery.updateRootCollectionDataByDocId(dbCollections.USERROLES,this.$route.params.id,updateField,updateRes=>{ 
                if(storeAssignUser.length > 0){
                    let count = 0;
                    let fileArry = storeAssignUser;
                    let userRole = this.userRole.name;
                    const uploadFileFunction = (row) => {
                    if(count >= fileArry.length){    
                        this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: 'Assigned employees update Successfully',
                                    icon: 'CoffeeIcon',
                                    variant: 'success',                               
                                },
                        })
                        this.show = false;
                        this.openRoleModel = false    
                        return           
                    }else{
                        self.updateEmployeeRole(row,userRole,this.$route.params.id,(saveRes)=>{
                            count += 1;
                            uploadFileFunction(fileArry[count]);
                        });      
                    }        
            }
            uploadFileFunction(fileArry[count]);  
           
                }else{   
                    this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: 'Assigned employees update Successfully',
                                icon: 'CoffeeIcon',
                                variant: 'success',                               
                            },
                    })
                    this.show = false;
                    this.openRoleModel = false     
                }                   
            })  
       },
       handleSubmit(){
        var self = this;   
        // Exit when the form isn't validEditFormValidator
        this.$refs.createuserRoleValid.validate().then(success=>{
                if(success){
                    this.modalSpinner = true                
                    let storeAssignUser = this.selectedUsers;
                    let count = 0;
                    let fileArry = this.previouseAssignUsers; //update employeeRole in user collection 
                    const uploadFileFunction = (row) => {
                    if(count >= fileArry.length){  
                        this.modalSpinner = false
                        self.updateFinalAssignUser(storeAssignUser)
                        return                 
                    }else{
                        self.removeEmpRole(row,(saveRes)=>{
                                count += 1;
                                uploadFileFunction(fileArry[count]);
                        }); 
                    }        
                    }
                    uploadFileFunction(fileArry[count]);                 
               }else{
                    console.log('ERROR')
                }
        })    
       },
       StudentInquiryChange(){  
           if(this.userRole.StudentInquiry.isSelected.length !== 0){
           this.userRole.StudentInquiry.allow = 'Read';
           this.$refs.updateuserRoleValid.reset()
               if(this.userRole.StudentInquiry.isSelected.length > 0){
                   this.userRole.StudentInquiry.isSelected.forEach(val=>{
                       let findInd = this.assignModules.findIndex(ele=>{
                           return ele==val
                       })
                       if(findInd > -1){
                           this.assignModules.splice(findInd,1)
                       }                       
                   })
               }
           }else{
           this.userRole.StudentInquiry.allow = false;
           if(this.userRole.StudentInquiry.isSelected.length > 0){
                   this.userRole.StudentInquiry.isSelected.forEach(val=>{
                       this.assignModules.push(val)
                   })
           }           
           }
       },
       StudentMgmtChange(){
           if(this.userRole.StudentMgmt.isSelected.length !== 0){
               this.userRole.StudentMgmt.allow = 'Read';
               this.$refs.updateuserRoleValid.reset()
               if(this.userRole.StudentMgmt.isSelected.length > 0){
                   this.userRole.StudentMgmt.isSelected.forEach(val=>{
                       let findInd = this.assignModules.findIndex(ele=>{
                           return ele==val
                       })
                       if(findInd > -1){
                           this.assignModules.splice(findInd,1)
                       }                       
                   })
               }
           }else{
           this.userRole.StudentMgmt.allow = false;
           this.$refs.updateuserRoleValid.reset()
               if(this.userRole.StudentMgmt.isSelected.length > 0){
                   this.userRole.StudentMgmt.isSelected.forEach(val=>{
                       this.assignModules.push(val)
                   })
               }
           }
       },
       AdminUserMgmtChange(){
           if(this.userRole.AdminUserMgmt.isSelected.length == 0){
               this.userRole.AdminUserMgmt.allow = false;
               this.$refs.updateuserRoleValid.reset()
               if(this.userRole.AdminUserMgmt.isSelected.length > 0){
                   this.userRole.AdminUserMgmt.isSelected.forEach(val=>{
                       let findInd = this.assignModules.findIndex(ele=>{
                           return ele==val
                       })
                       if(findInd > -1){
                           this.assignModules.splice(findInd,1)
                       }                       
                   })
               }
           }else{
               this.userRole.AdminUserMgmt.allow = 'Read';
               this.$refs.updateuserRoleValid.reset()
               if(this.userRole.AdminUserMgmt.isSelected.length > 0){
                   this.userRole.AdminUserMgmt.isSelected.forEach(val=>{
                       this.assignModules.push(val)
                   })
               }
           }
       },
       UniversityCourseMgmtChange(){
        if(this.userRole.UniversityCourseMgmt.isSelected.length == 0){
               this.userRole.UniversityCourseMgmt.allow = false
               this.$refs.updateuserRoleValid.reset()
               if(this.userRole.UniversityCourseMgmt.isSelected.length > 0){
                   this.userRole.UniversityCourseMgmt.isSelected.forEach(val=>{
                       let findInd = this.assignModules.findIndex(ele=>{
                           return ele==val
                       })
                       if(findInd > -1){
                           this.assignModules.splice(findInd,1)
                       }                       
                   })
               }
           }else{
               this.userRole.UniversityCourseMgmt.allow = 'Read'
               this.$refs.updateuserRoleValid.reset()
               if(this.userRole.UniversityCourseMgmt.isSelected.length > 0){
                   this.userRole.UniversityCourseMgmt.isSelected.forEach(val=>{
                       this.assignModules.push(val)
                   })
               }
           }   
       },
       docModuleMgmtChange(){
          if(this.userRole.docModuleMgmt.isSelected.length == 0){
               this.userRole.docModuleMgmt.allow = false
               this.$refs.updateuserRoleValid.reset()
               if(this.userRole.docModuleMgmt.isSelected.length > 0){
                   this.userRole.docModuleMgmt.isSelected.forEach(val=>{
                       let findInd = this.assignModules.findIndex(ele=>{
                           return ele==val
                       })
                       if(findInd > -1){
                           this.assignModules.splice(findInd,1)
                       }                       
                   })
               }
           }else{
               this.userRole.docModuleMgmt.allow = 'Read';
               this.$refs.updateuserRoleValid.reset()
               if(this.userRole.docModuleMgmt.isSelected.length > 0){
                   this.userRole.docModuleMgmt.isSelected.forEach(val=>{
                       this.assignModules.push(val)
                   })
               }
           }    
       },
       companyDocChange(){           
           if(this.userRole.companyDoc.isSelected.length == 0){
               this.userRole.companyDoc.allow = false
               this.$refs.updateuserRoleValid.reset()
               if(this.userRole.companyDoc.isSelected.length > 0){
                   this.userRole.companyDoc.isSelected.forEach(val=>{
                       let findInd = this.assignModules.findIndex(ele=>{
                           return ele==val
                       })
                       if(findInd > -1){
                           this.assignModules.splice(findInd,1)
                       }                       
                   })
               }
           }else{
               this.userRole.companyDoc.allow = 'Read'
               this.$refs.updateuserRoleValid.reset()
               if(this.userRole.companyDoc.isSelected.length > 0){
                   this.userRole.companyDoc.isSelected.forEach(val=>{
                       this.assignModules.push(val)
                   })
               }
           }
       },
       promotionalChange(){           
           if(this.userRole.promotional.isSelected.length == 0){
               this.userRole.promotional.allow = false
               this.$refs.updateuserRoleValid.reset()
               if(this.userRole.promotional.isSelected.length > 0){
                   this.userRole.promotional.isSelected.forEach(val=>{
                       let findInd = this.assignModules.findIndex(ele=>{
                           return ele==val
                       })
                       if(findInd > -1){
                           this.assignModules.splice(findInd,1)
                       }                       
                   })
               }
           }else{
               this.userRole.promotional.allow = 'Read'
               this.$refs.updateuserRoleValid.reset()
               if(this.userRole.promotional.isSelected.length > 0){
                   this.userRole.promotional.isSelected.forEach(val=>{
                       this.assignModules.push(val)
                   })
               }
           }
       },
        announcementChange(){           
           if(this.userRole.announcement.isSelected.length == 0){
               this.userRole.announcement.allow = false
               this.$refs.updateuserRoleValid.reset()
               if(this.userRole.announcement.isSelected.length > 0){
                   this.userRole.announcement.isSelected.forEach(val=>{
                       let findInd = this.assignModules.findIndex(ele=>{
                           return ele==val
                       })
                       if(findInd > -1){
                           this.assignModules.splice(findInd,1)
                       }                       
                   })
               }
           }else{
               this.userRole.announcement.allow = 'Read'
               this.$refs.updateuserRoleValid.reset()
               if(this.userRole.announcement.isSelected.length > 0){
                   this.userRole.announcement.isSelected.forEach(val=>{
                       this.assignModules.push(val)
                   })
               }
           }
       },
        communityChange(){           
           if(this.userRole.community.isSelected.length == 0){
               this.userRole.community.allow = false
               this.$refs.updateuserRoleValid.reset()
               if(this.userRole.community.isSelected.length > 0){
                   this.userRole.community.isSelected.forEach(val=>{
                       let findInd = this.assignModules.findIndex(ele=>{
                           return ele==val
                       })
                       if(findInd > -1){
                           this.assignModules.splice(findInd,1)
                       }                       
                   })
               }
           }else{
               this.userRole.community.allow = 'Read'
               this.$refs.updateuserRoleValid.reset()
               if(this.userRole.community.isSelected.length > 0){
                   this.userRole.community.isSelected.forEach(val=>{
                       this.assignModules.push(val)
                   })
               }
           }
        },
        infoDocChange(){           
           if(this.userRole.infoDoc.isSelected.length == 0){
               this.userRole.infoDoc.allow = false
               this.$refs.updateuserRoleValid.reset()
               if(this.userRole.infoDoc.isSelected.length > 0){
                   this.userRole.infoDoc.isSelected.forEach(val=>{
                       let findInd = this.assignModules.findIndex(ele=>{
                           return ele==val
                       })
                       if(findInd > -1){
                           this.assignModules.splice(findInd,1)
                       }                       
                   })
               }
           }else{
               this.userRole.infoDoc.allow = 'Read'
               this.$refs.updateuserRoleValid.reset()
               if(this.userRole.infoDoc.isSelected.length > 0){
                   this.userRole.infoDoc.isSelected.forEach(val=>{
                       this.assignModules.push(val)
                   })
               }
           }
        },
        fiedlMgntChange(){           
           if(this.userRole.eduMent.isSelected.length == 0){
               this.userRole.eduMent.allow = false
               this.$refs.updateuserRoleValid.reset()
               if(this.userRole.eduMent.isSelected.length > 0){
                   this.userRole.eduMent.isSelected.forEach(val=>{
                       let findInd = this.assignModules.findIndex(ele=>{
                           return ele==val
                       })
                       if(findInd > -1){
                           this.assignModules.splice(findInd,1)
                       }                       
                   })
               }
           }else{
               this.userRole.eduMent.allow = 'Read'
               this.$refs.updateuserRoleValid.reset()
               if(this.userRole.eduMent.isSelected.length > 0){
                   this.userRole.eduMent.isSelected.forEach(val=>{
                       this.assignModules.push(val)
                   })
               }
           }
        },
        noticeMangChange(){           
           if(this.userRole.noticeDoc.isSelected.length == 0){
               this.userRole.noticeDoc.allow = false
               this.$refs.updateuserRoleValid.reset()
               if(this.userRole.noticeDoc.isSelected.length > 0){
                   this.userRole.noticeDoc.isSelected.forEach(val=>{
                       let findInd = this.assignModules.findIndex(ele=>{
                           return ele==val
                       })
                       if(findInd > -1){
                           this.assignModules.splice(findInd,1)
                       }                       
                   })
               }
           }else{
               this.userRole.noticeDoc.allow = 'Read'
               this.$refs.updateuserRoleValid.reset()
               if(this.userRole.noticeDoc.isSelected.length > 0){
                   this.userRole.noticeDoc.isSelected.forEach(val=>{
                       this.assignModules.push(val)
                   })
               }
           }
        },
        noteMangChange(){           
           if(this.userRole.noteDoc.isSelected.length == 0){
               this.userRole.noteDoc.allow = false
               this.$refs.updateuserRoleValid.reset()
               if(this.userRole.noteDoc.isSelected.length > 0){
                   this.userRole.noteDoc.isSelected.forEach(val=>{
                       let findInd = this.assignModules.findIndex(ele=>{
                           return ele==val
                       })
                       if(findInd > -1){
                           this.assignModules.splice(findInd,1)
                       }                       
                   })
               }
           }else{
               this.userRole.noteDoc.allow = 'Read'
               this.$refs.updateuserRoleValid.reset()
               if(this.userRole.noteDoc.isSelected.length > 0){
                   this.userRole.noteDoc.isSelected.forEach(val=>{
                       this.assignModules.push(val)
                   })
               }
           }
        },
       updateEmployeeRole(row,userRole,roleId,cb){
           // var self = this;   
            let updateField = {
                'employeeRole':{
                    'id':roleId,
                    'name':userRole
                },
                'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp()
            }
            updateQuery.updateRootCollectionDataByDocId(dbCollections.USERS,row,updateField,updateRes=>{
                cb(updateRes)
            })
       },
       handleSubmitedit(){
        var self = this;
        let storedUser = self.selectedUsers;
         // Exit when the form isn't validEditFormValidator
        this.$refs.updateuserRoleValid.validate().then(success=>{
                if(success){                    
                    this.isSpinner = true;
                    this.modalSpinner = true;
                    let roleModules = [];                   
                    if(this.userRole.StudentInquiry.isSelected.length > 0){
                        roleModules.push({
                            name:this.userRole.StudentInquiry.isSelected[0],
                            allow:this.userRole.StudentInquiry.allow
                        }) 
                    }
                    if(this.userRole.StudentMgmt.isSelected.length > 0){
                        roleModules.push({
                            name:this.userRole.StudentMgmt.isSelected[0],
                            allow:this.userRole.StudentMgmt.allow
                        }) 
                    }
                    if(this.userRole.AdminUserMgmt.isSelected.length > 0){
                        roleModules.push({
                            name:this.userRole.AdminUserMgmt.isSelected[0],
                            allow:this.userRole.AdminUserMgmt.allow
                        }) 
                    }
                    if(this.userRole.UniversityCourseMgmt.isSelected.length > 0){
                        roleModules.push({
                            name:this.userRole.UniversityCourseMgmt.isSelected[0],
                            allow:this.userRole.UniversityCourseMgmt.allow
                        }) 
                    }
                    if(this.userRole.docModuleMgmt.isSelected.length > 0){
                        roleModules.push({
                            name:this.userRole.docModuleMgmt.isSelected[0],
                            allow:this.userRole.docModuleMgmt.allow
                        }) 
                    }
                    if(this.userRole.companyDoc.isSelected.length > 0){
                        roleModules.push({
                            name:this.userRole.companyDoc.isSelected[0],
                            allow:this.userRole.companyDoc.allow
                        }) 
                    }  
                    if(this.userRole.promotional.isSelected.length > 0){
                        roleModules.push({
                            name:this.userRole.promotional.isSelected[0],
                            allow:this.userRole.promotional.allow
                        }) 
                    }
                    if(this.userRole.announcement.isSelected.length > 0){
                        roleModules.push({
                            name:this.userRole.announcement.isSelected[0],
                            allow:this.userRole.announcement.allow
                        }) 
                    }
                    if(this.userRole.community.isSelected.length > 0){
                        roleModules.push({
                            name:this.userRole.community.isSelected[0],
                            allow:this.userRole.community.allow
                        }) 
                    }      
                    if(this.userRole.infoDoc.isSelected.length > 0){
                        roleModules.push({
                            name:this.userRole.infoDoc.isSelected[0],
                            allow:this.userRole.infoDoc.allow
                        }) 
                    }      
                    if(this.userRole.eduMent.isSelected.length > 0){
                        roleModules.push({
                            name:this.userRole.eduMent.isSelected[0],
                            allow:this.userRole.eduMent.allow
                        })
                    }
                    if(this.userRole.noticeDoc.isSelected.length > 0){
                        roleModules.push({
                            name:this.userRole.noticeDoc.isSelected[0],
                            allow:this.userRole.noticeDoc.allow
                        }) 
                    }      
                    if(this.userRole.noteDoc.isSelected.length > 0){
                        roleModules.push({
                            name:this.userRole.noteDoc.isSelected[0],
                            allow:this.userRole.noteDoc.allow
                        }) 
                    }      
                   
                    //REMOVE ROLEID AND NAME IN USERS
                    if(self.previouseAssignUsers.length > 0){  
                        let count = 0;
                        let fileArry = self.previouseAssignUsers; //update employeeRole in user collection 
                        const uploadFileFunction = (row) => {
                        if(count >= fileArry.length){  
                            self.updateFinalUserRole(roleModules,storedUser)
                            this.modalSpinner = false;
                            return                 
                        }else{
                            self.removeEmpRole(row,(saveRes)=>{
                                    count += 1;
                                    uploadFileFunction(fileArry[count]);
                            }); 
                        }        
                        }
                        uploadFileFunction(fileArry[count]);                       
                    }else{
                        this.modalSpinner = false;
                        self.updateFinalUserRole(roleModules,storedUser)
                    }                  
                }
        })
       },
       updateFinalUserRole(roleModules,storedUser){
        var self = this;
        if(storedUser.length > 0){
            storedUser.forEach(elem=>{
                if(elem){
                    let searchIndex = this.filteredUsers.findIndex(val=>{
                        return val.id == elem
                    })
                    if(searchIndex <= -1){
                        storedUser.splice(searchIndex,1)
                    }
                }else{
                    return
                }
            })        
        }
        let saveObj = {
        'userRoleName':this.userRole.name,                       
        'assignUser':storedUser,
        'assignModules':roleModules,                        
        }                   
        updateQuery.updateRootCollectionDataByDocId(dbCollections.USERROLES,this.$route.params.id,saveObj,updateRes=>{                   
            let count = 0;
            let fileArry = storedUser;
            let userRole = this.userRole.name;
            if(storedUser !== null){
                const uploadFileFunction = (row) => {
                if(count >= fileArry.length){    
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Employee Role Updated Successfully',
                            icon: 'CoffeeIcon',
                            variant: 'success',                               
                        },
                    })
                    this.editable = false;
                    this.isSpinner = false;
                    this.init();    
                    return           
                }else{
                    this.updateEmployeeRole(row,userRole,this.$route.params.id,(saveRes)=>{
                        count += 1;
                        uploadFileFunction(fileArry[count]);
                    });      
                }        
            }
            uploadFileFunction(fileArry[count]);  
            }else{
                this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Employee Role Updated Successfully',
                            icon: 'CoffeeIcon',
                            variant: 'success',                               
                        },
                })
                this.editable = false;
                this.isSpinner = false; 
                this.init();  
            }
        })
      
      }, 
       updateUserRole(event){
        // Prevent modal from closing
        event.preventDefault()
        // Trigger submit handler
        this.handleSubmitedit()        
       },
    },
    computed: {
    filteredUsers() {
       var self = this;
       var tempUsers = self.AllUsers
       var userData = JSON.parse(localStorage.getItem('userData'))
       if(tempUsers.length > 0){
           tempUsers.forEach((ele,index)=>{
               if(ele.id == userData.id){ //if current user is employee then not allow to update its own role
                   tempUsers[index].currnetEmp = ele.id
                //    tempUsers.splice(index,1)
               }
           })
       }
         // SEARCH BY NAME, DESIGNATION & EMPLOYEEID	
       if (self.searchValue != '' && self.searchValue) {	
             tempUsers = tempUsers.filter((item) => {	
              return item.name.toLowerCase().indexOf(self.searchValue.toLowerCase()) >= 0	         
             })	
         }	
       return tempUsers	
     }
    }
}
</script>
<style scoped>
.overselect {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.dropdown { 
  position: relative; 
  cursor: pointer;
}
.multiselect {
  position: relative;
}
.multiselect ul{
    border: 1px solid #ddd;
    border-top: 0;
    border-radius: 0 0 3px 3px;
    left: 0px;
    padding: 8px 8px;
    position: absolute;
    top: 0rem;
    width: 100%;
    background-color: white;
    list-style: none;
    max-height: 150px;
    overflow: auto;
    z-index: 99;
}
.position-relative.employee_add_table {
    padding: 0px!important;
}
</style>