const formatPhoneNumber = (phoneNumber,mask) => {
    if (!mask || mask.length === 0) {
        return phoneNumber;
    }
    if (!phoneNumber) {
        return phoneNumber;
    }
    let fOrmattadNumber = '';
    let currentIndex = 0;

    for (let i = 0; i < mask.length; i++) {
        if (mask[i] === '9') {
            if (currentIndex >= phoneNumber.length) {
                break;
            }
            fOrmattadNumber += phoneNumber[currentIndex];
            currentIndex++;
        } else {
            fOrmattadNumber += mask[i];
        }
    }
    return fOrmattadNumber
}

export default formatPhoneNumber